<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :src="orgData.logo_url"
          :text="avatarText(orgData.name)"
          :variant="`light-${resolveOrgRoleVariant(orgData.role)}`"
          size="100px"
          rounded
        />
      </template>
      <div class="mb-1">
        <h4 class="mb-0">
          {{ orgData.name }}
        </h4>
        <span class="card-text">{{ orgData.full_name }}</span>
      </div>

      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span
            v-if="typeof orgData.logo_url !== 'object'"
            class="d-none d-sm-inline"
          >
            Change Logo
          </span>
          <span
            v-else
            class="d-none d-sm-inline"
          >
            Add Logo
          </span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          v-if="typeof orgData.logo_url !== 'object'"
          variant="outline-secondary"
          class="ml-1"
          @click="removeImage()"
        >
          <span class="d-none d-sm-inline">Remove Logo</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <b-row>
      <b-col
        cols="12"
      >
        <!-- Org Info: Input Fields -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            autocomplete="off"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>

              <!-- Field: Orgname -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Brand name"
                  rules="required|max:100"
                >
                  <b-form-group
                    label="Brand name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="orgData.name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                      autocomplete="off"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Full Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Full legal name"
                  rules="required|max:100"
                >
                  <b-form-group
                    label="Full legal name"
                    label-for="full-name"
                  >
                    <b-form-input
                      id="full-name"
                      v-model="orgData.full_name"
                      :state="getValidationState(validationContext)"
                      trim
                      autocomplete="off"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Email -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="email"
                >
                  <b-form-group
                    label="Public email address"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="orgData.email"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Website -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Website"
                >
                  <b-form-group
                    label="Website"
                    label-for="website"
                  >
                    <b-form-input
                      id="website"
                      v-model="orgData.website"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Domains -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Domains"
                >
                  <b-form-group
                    label="Domains"
                    label-for="domains"
                  >
                    <b-form-input
                      id="domains"
                      v-model="orgData.domains"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Industry -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Industry"
                  rules="required|min:3|max:100"
                >
                  <b-form-group
                    label="Industry"
                    label-for="industry"
                  >
                    <b-form-input
                      id="industry"
                      v-model="orgData.industry"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Country -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    label="Country"
                    label-for="country"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="orgData.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countries"
                      :state="getValidationState(validationContext)"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="country"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>



              <!-- Field: Platform tags -->
              <b-col
                cols="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Platforms"
                >
                  <b-form-group
                    label="Platforms"
                    label-for="platforms"
                  >
                    <b-form-tags
                      v-model="orgData.platforms"
                      input-id="platforms"
                      separator=","
                      remove-on-delete
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Bio -->
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Bio"
                  label-for="org-bio"
                >
                  <b-form-textarea
                    id="bio"
                    v-model="orgData.bio"
                    rows="4"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Save Changes
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'myorg-view' }"
            >
              Cancel
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
  BFormTextarea,
  BFormInvalidFeedback,
  BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import countries from '@/countries-data'
import useOrgsList from '../../org/orgs-list/useOrgsList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormTextarea,
    BFormInvalidFeedback,
    BForm,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props) {
    const toast = useToast()
    const { resolveOrgRoleVariant, statusOptions } = useOrgsList()

    if (props.orgData.adviser_user !== null) {
      // eslint-disable-next-line no-param-reassign
      props.orgData.adviser_id = props.orgData.adviser_user.id
    } else {
      // eslint-disable-next-line no-param-reassign
      props.orgData.adviser_id = null
    }

    // console.log(props.orgData)

    // Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.orgData.logo_url = base64
    })

    const removeImage = () => {
      // eslint-disable-next-line no-param-reassign
      props.orgData.logo_url = null
    }

    const onSubmit = () => {
      store.dispatch('app/updateOrg', { id: router.currentRoute.params.id, orgData: props.orgData })
        .then(() => {
          router.push({ name: 'myorg-view' })
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating organisation.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }


    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      resolveOrgRoleVariant,
      avatarText,
      statusOptions,

      // form
      onSubmit,
      refFormObserver,
      getValidationState,

      // Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      removeImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
